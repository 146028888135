import React from "react";
import { MessageType } from "../CreateLanternPage";

type LanterUserDetailsEditorType = {
  message: MessageType;
  sticker: string;
  handleSetLocation: (location: string) => void;
  handleSetUser: (user: string) => void;
  user: string;
  location: string;
};

function LanterUserDetailsEditor(props: LanterUserDetailsEditorType) {
  return (
    <>
      <form className="userDetails">
        <input
          type="text"
          name="user"
          autoComplete="off"
          defaultValue={props.user}
          onChange={(e) => props.handleSetUser(e.target.value)}
          placeholder="Your name"
        />
        <input
          type="text"
          name="location"
          autoComplete="off"
          defaultValue={props.location}
          onChange={(e) => props.handleSetLocation(e.target.value)}
          placeholder="Your location"
        />
      </form>
    </>
  );
}

export default LanterUserDetailsEditor;
