export const STICKERS = [
  "sticker_1.svg",
  "sticker_2.svg",
  "sticker_3.svg",
  "sticker_4.svg",
  "sticker_5.svg",
  "sticker_6.svg",
  "sticker_7.svg",
  "sticker_8.svg",
  "sticker_9.svg",
  "sticker_10.svg",
  "sticker_11.svg",
  "sticker_12.svg",
];
