import React, { useState, useEffect, useRef } from "react";
import { useWindowSize } from "@hooks";
import { MessageType } from "../CreateLanternPage";
import { FONTS, DEFAULT_MESSAGE } from "@constants/fonts";

type MessageEditorTypes = {
  message: MessageType;
  handleSetMessage: (value: string) => void;
  handleSetFont: (font: string) => void;
};

const MessageEditor = (props: MessageEditorTypes) => {
  const [windowWidth, windowHeight] = useWindowSize();
  const lanternMessagePlaceholder = useRef(null);

  const fontsSelectionRef = useRef(null);

  const [fontsMenu, openFontsMenu] = useState(false);

  const [isMobileSafari, setIsMobileSafari] = useState(false);

  const handleSafariFocusOut = () => {
    if (!isMobileSafari) return;
    window.scrollTo(0, 0);
    setTimeout(() => {
      fontsSelectionRef.current.style.bottom = 0;
    });
  };

  const handleSafariFocusIn = () => {
    if (!isMobileSafari) return;
    window.scrollTo(0, 0);
    setTimeout(() => {
      fontsSelectionRef.current.style.bottom = "11vh";
    });
  };

  useEffect(() => {
    setIsMobileSafari(
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        window.innerHeight < 960
    );
    document.addEventListener("focusout", handleSafariFocusOut);
    return () => {
      document.addEventListener("focusout", handleSafariFocusOut);
    };
  }, []);

  const handleClick = (e) => {
    const isUserReadyToType = lanternMessagePlaceholder.current.contains(
      e.target
    );

    openFontsMenu(isUserReadyToType);

    // TODO: A bit ugly.. + REPEATING code :/  but let's look at it tomorrow..
    document.querySelector(".createPage").dataset.isMobileUserUpdatingLantern =
      window.innerWidth < 960 && isUserReadyToType;
  };

  function handleOnFocus(e) {
    if (props.message.message === DEFAULT_MESSAGE) {
      props.handleSetMessage("");
    }
    e.currentTarget.select();
    handleSafariFocusIn();
  }

  function handleOnInput(e) {
    props.handleSetMessage(e.currentTarget.value);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const isDefaultMessageOnMobile =
    props.message.message === DEFAULT_MESSAGE && windowWidth < 960;

  return (
    <div ref={lanternMessagePlaceholder}>
      <div className="messagePlaceholder">
        <textarea
          maxLength="90"
          style={{
            fontFamily: isDefaultMessageOnMobile
              ? "sans-serif"
              : props.message.fontType,
            fontStyle: isDefaultMessageOnMobile ? "normal" : "italic",
            lineHeight: isDefaultMessageOnMobile ? 1.5 : "inherit",
          }}
          value={
            isDefaultMessageOnMobile
              ? `${props.message.message}\n(90 characters max)`
              : props.message.message
          }
          className={`messageEditor fontType${FONTS.indexOf(
            props.message.fontType
          )}`}
          onInput={handleOnInput}
          onFocus={handleOnFocus}
        />
      </div>
      {fontsMenu && (
        <div className="fontsSelection" ref={fontsSelectionRef}>
          {FONTS.map((font, index) => (
            <div
              key={index}
              onClick={() => {
                props.handleSetFont(font);
                handleSafariFocusOut();
              }}
              style={{ fontFamily: font }}
              className={props.message.fontType === font ? "selectedFont" : ""}
            >
              {index === 5 ? "写你的愿望" : DEFAULT_MESSAGE}
            </div>
          ))}
        </div>
      )}

      <div
        className="charCounter"
        style={{
          color:
            props.message.message.length > 80
              ? "red"
              : props.message.message.length > 60
              ? "orange"
              : "white",
        }}
      >
        {90 - props.message.message.length}{" "}
        {props.message.message.length === 89 ? "character" : "characters"} left
      </div>
    </div>
  );
};

export default MessageEditor;
