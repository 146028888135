import React, { useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { Howl } from "howler";
import { FONTS, DEFAULT_MESSAGE } from "@constants/fonts";
import { Layout } from "@components/Layout";
import StickerPlaceholder from "@components/StickerPlaceholder";
import MessageEditor from "@components/MessageEditor";
import SentMessage from "@components/SentMessage";
import LanternLayoutEditor from "@components/LanternLayoutEditor";
import LanterUserDetailsEditor from "@components/LanterUserDetailsEditor";
import { Title } from "@components/Title";
import { HeadingVariant } from "@components/Title/types";
import {
  ButtonSeeMyLantern,
  ButtonReadyToFly,
  ButtonAnchor,
} from "@components/Button/types";
import "@styles/CreateLantern.scss";
import fireCracking from "@assets/audio/fire_video_background.mp3";
import publicIp from "public-ip";

export type MessageType = {
  message: string | null;
  fontType: string | null;
};

const CreateLanternPage = (props) => {
  const [sticker, setSticker] = useState("");
  const [step, setStep] = useState(1);
  const [user, setUser] = useState("");
  const [location, setLocation] = useState("");
  const [userIP, setUserIP] = useState("");

  console.log("userIP", userIP);

  const [lanternStyle, setLanternStyle] = useState(1);

  const [message, setMessage] = useState({
    message: DEFAULT_MESSAGE,
    fontType: FONTS[0],
  });

  function handleSetSticker(sticker) {
    setSticker(sticker);
  }

  function handleSetUser(user) {
    setUser(user);
  }

  function handleSetLocation(location) {
    setLocation(location);
  }

  function handleSetMessage(value) {
    setMessage({ ...message, message: value });
  }

  function handleSetFont(font) {
    setMessage({ ...message, fontType: font });
  }

  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });

  getClientIp().then((res) => setUserIP(res));

  function addToDB() {
    if (firebase.database()) {
      firebase.database().ref(`/lanterns`).push({
        message,
        user,
        location,
        sentDate: Date.now(),
        lanternStyle,
        sticker,
        read: 0,
        active: true,
        userAgent: navigator.userAgent,
        language: navigator.language,
        userIP,
      });
    }
  }

  const headings = [
    {
      value: "CREATE",
      priority: 2,
      type: HeadingVariant.Bold,
    },
    {
      value: "Your Lantern",
      priority: 1,
    },
  ];

  function validateLantern() {
    if (message.message === DEFAULT_MESSAGE) {
      return "Please add your wishes";
    }
    if (message.message === "") {
      return "Please add your wishes";
    }
    if (message.message.length < 5) {
      return "Your wish should be longer";
    }
    if (user === "" && step === 2) {
      return "Please add your name";
    }
    if (location === "" && step === 2) {
      return "Please add your location";
    }

    return null;
  }

  const fireCrackingSound = new Howl({
    src: fireCracking,
  });

  const playFireCrackingSound = () => {
    fireCrackingSound.play();
    fireCrackingSound.volume(0.3);
    fireCrackingSound.fade(0.3, 0.0, 8000);
  };

  return (
    <Layout
      currentPath={props.location.pathname}
      className="createPage"
      lanternsBackground
      pageTitle="Create your lantern | EMBRACE PS"
    >
      <div className={`titleExapander step${step}`}>
        <Title headings={headings} />
      </div>
      {step === 1 && (
        <div className="createLanternContainer">
          <div>
            <LanternLayoutEditor
              lanternStyle={lanternStyle}
              setLanternStyle={setLanternStyle}
              step={step}
            />
            <MessageEditor
              message={message}
              handleSetFont={handleSetFont}
              handleSetMessage={handleSetMessage}
            />
            <StickerPlaceholder
              handleSetSticker={handleSetSticker}
              sticker={sticker}
            />
          </div>
        </div>
      )}

      {step > 1 && (
        <>
          <div className="lanternWithData">
            <LanternLayoutEditor
              lanternStyle={lanternStyle}
              setLanternStyle={setLanternStyle}
              step={step}
            />
            <div className="lockedData">
              <div
                className={`message fontType${FONTS.indexOf(message.fontType)}`}
                style={{
                  fontFamily: message.fontType,
                }}
              >
                {message.message}
              </div>
              <div className="image">
                {sticker && (
                  <img
                    src={require(`@assets/images/stickers/${sticker}`)}
                    alt={sticker}
                  />
                )}
              </div>
            </div>
          </div>
          {step === 2 && (
            <LanterUserDetailsEditor
              message={message}
              sticker={sticker}
              handleSetLocation={handleSetLocation}
              handleSetUser={handleSetUser}
              user={user}
              location={location}
            />
          )}
        </>
      )}

      <div className="createButtonsWrap">
        {step === 1 && (
          <ButtonSeeMyLantern
            errorMessage={validateLantern()}
            disabled={validateLantern()}
            onClick={() => setStep(2)}
          />
        )}
        {step === 2 && (
          <>
            <ButtonReadyToFly
              errorMessage={validateLantern()}
              disabled={validateLantern()}
              onClick={() => {
                playFireCrackingSound();
                addToDB();
                setStep(3);
              }}
            />
            <ButtonAnchor
              isSimpleAnchor
              className="previousButton"
              onClick={() => setStep(1)}
            >
              Back
            </ButtonAnchor>
          </>
        )}
      </div>

      {step === 3 && <SentMessage step={step} />}
    </Layout>
  );
};

export default CreateLanternPage;
