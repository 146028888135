import React, { useState, useEffect, useRef } from "react";
import { removeExtension } from "../../utils";
import { STICKERS } from "../../constants/stickers";
import { ButtonAnchor } from "@components/Button/types";
import { navigate } from "gatsby";

type SentMessageTypes = {
  step: number;
};

const SentMessage = (props: SentMessageTypes) => {
  useEffect(() => {
    const redirectTimeout = window.setTimeout(() => {
      navigate("/lanterns");
    }, 20000);

    return () => window.clearTimeout(redirectTimeout);
  }, []);

  return (
    <>
      <div className="sentMessage">
        <p>
          Thank you for creating your virtual lantern. <br />
          By staying home and not releasing a real one during the pandemic you
          will help keep people safe.
          <br />
          <br />
          You’ll also be saving wildlife and the environment from the potential
          dangers of burning and discarded lanterns.
          <br />
          &nbsp;
        </p>

        <ButtonAnchor href="/lanterns">Explore virtual lanterns</ButtonAnchor>
        <img
          className="publicisGroupe"
          src={require("@assets/images/publicis-groupe.png")}
        />
      </div>
      <div className="teamMembers">
        TEAM
        <br />
        <a target="_blank" href="https://marcel.ai/app/person/1065208">
          Elena Rey
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/1794322">
          Juliana Lua
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/852833">
          Nebojsa Kosijer
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/2128420">
          Radoslav Naydenov
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/798913">
          Andrea Mercado
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/930715">
          Jo Hippolyte
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/2331922">
          Neal Lankester
        </a>{" "}
        |{" "}
        <a target="_blank" href="https://marcel.ai/app/person/872391">
          Stephen "Boz" Boswell
        </a>
        <br />
        <br />
        <a
          className="mainLink"
          target="_blank"
          href="https://marcel.ai/app/communities/business-resource-group/49b63fd5-9962-5877-908b-8015feb2bd13"
        >
          Visit the EMBRACE Community on Marcel
        </a>
      </div>
    </>
  );
};

export default SentMessage;
