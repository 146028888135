import React, { useState, useEffect, useRef } from "react";
import { removeExtension } from "../../utils";
import { STICKERS } from "@constants/stickers";

type StickerPlaceholderTypes = {
  sticker: string;
  handleSetSticker: (sticker: string) => void;
};

const StickerPlaceholder = (props: StickerPlaceholderTypes) => {
  const lanternPlaceHolder = useRef(null);

  const [stickersMenu, openStickersMenu] = useState(false);

  const handleClick = (e) => {
    const isUserReadyToSelectSticker = lanternPlaceHolder.current.contains(
      e.target
    );

    openStickersMenu(isUserReadyToSelectSticker);

    // Mobile Safari hack (:
    if (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      window.innerHeight < 960
    ) {
      window.scrollTo(0, 0);
    }

    // TODO: A bit ugly.. + REPEATING code :/  but let's look at it tomorrow..
    document.querySelector(".createPage").dataset.isMobileUserUpdatingLantern =
      window.innerWidth < 960 &&
      (isUserReadyToSelectSticker ||
        e.target.classList.contains("messageEditor"));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const sticker = props.sticker;
  return (
    <div ref={lanternPlaceHolder}>
      <div
        className={`stickerPlaceholder ${sticker && "stickerSet"}`}
        onClick={handleClick}
      >
        {sticker ? (
          <img
            src={require(`@assets/images/stickers/${sticker}`)}
            alt={removeExtension(sticker)}
          />
        ) : (
          <img
            src={require(`@assets/icons/add_sticker.svg`)}
            alt="Add Sticker"
            className="addSticker"
          />
        )}
      </div>
      {stickersMenu && (
        <div className="stickersSelection">
          {STICKERS.map((sticker, index) => (
            <img
              key={index}
              alt={removeExtension(sticker)}
              src={require(`@assets/images/stickers/${sticker}`)}
              onClick={() => {
                props.handleSetSticker(sticker);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default StickerPlaceholder;
