import React from "react";
import { LANTERN_TYPES } from "@constants/lanterns";

type LanternLayoutEditorType = {
  lanternStyle: number;
  setLanternStyle: (lantern: number) => void;
  step: number;
};

function LanternLayoutEditor(props: LanternLayoutEditorType) {
  return (
    <>
      <div
        className="lanternStyleSeletion"
        style={{
          width: `${LANTERN_TYPES.length * 6}vh`,
          maxHeight: props.step === 1 ? "100vh" : 0,
        }}
      >
        {LANTERN_TYPES.map((image, idx) => (
          <img
            key={idx}
            onClick={() => props.setLanternStyle(idx + 1)}
            src={require(`@assets/lanternImages/${image}`)}
            className={
              props.lanternStyle === idx + 1 ? "active" : "lanternImage"
            }
          />
        ))}
      </div>

      <img
        className={`selectedLantern step${props.step}`}
        src={require(`@assets/lanternImages/lantern_${props.lanternStyle}.png`)}
      />
    </>
  );
}

export default LanternLayoutEditor;
